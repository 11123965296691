<template>
  <div class="common-voucher-page">
    <div class="content_left">
      <treedata @projectId-child="projectIdClick"></treedata>
    </div>
    <div class="common-voucher-content">
      <div class="voucher-header-upload">
        <span>关键字:</span>
        <el-input placeholder="请输入关键字" v-model="Key"></el-input>
        <span>所属单位:</span>
        <el-input placeholder="请输入" v-model="HospitalName"></el-input>
        <span>序号:</span>
        <el-input placeholder="请输入" v-model="SerialNumber"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="queryList">查询</el-button>
        <div style="margin-top: 20px">
          <el-button type="primary" icon="el-icon-top" @click="add">上传文件</el-button>
          <el-button type="primary" icon="el-icon-bottom" @click="downFiles">打包下载</el-button>
          <el-button type="primary" icon="el-icon-bottom" @click="exportFiles">导出表格</el-button>
        </div>
      </div>
      <div style="display: flex;position: relative">
        <div class="content" style="height: 610px;width: 100%">
          <el-table
            :data="tableData"
            max-height="610"
            :row-key="getRowKey"
            border
            fit
            ref="multipleTable"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
            :reserve-selection="true"
              align="center"
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              label="序号"
              type="index"
              align="center"
              width="62">
            </el-table-column>
            <el-table-column
              label="文件名称"
              prop="ArchivesName"
              align="center"
              sortable
              min-width="150">
              <template slot-scope="scope">
                <span style="cursor: pointer" @click="seeDialog(scope.row)">{{scope.row.ArchivesName}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="发表日期"
              prop="IssuingDate"
              align="center"
              sortable
              :formatter="formatter"
              width="150">
            </el-table-column>
            <el-table-column
              label="期刊名称"
              prop="JournalArticle"
              align="center"
              sortable
              width="200">
            </el-table-column>
            <el-table-column
              prop="ArchivesUser"
              label="作者(1-N)"
              align="center"
              sortable
              width="150">
            </el-table-column>
            <el-table-column
              prop="Publisher"
              label="出版单位"
              align="center"
              sortable
              min-width="150">
            </el-table-column>
            <el-table-column
              prop="SerialNumber"
              label="课题/子课题序号"
              align="center"
              sortable
              min-width="170">
            </el-table-column>
            <el-table-column
              prop="HospitalName"
              label="所属单位"
              align="center"
              sortable
              min-width="200">
            </el-table-column>
            <el-table-column
              prop="Remarks"
              label="备注"
              align="center"
              sortable
              min-width="200">
            </el-table-column>
            <el-table-column label="操作" align="center" width="220" fixed="right">
              <template slot-scope="scope">
                <el-link
                  style="margin-right: 11px"
                  type="text"
                  :underline="false"
                  @click.stop="edit(scope.row)"
                ><i class="el-icon-edit-outline"></i>编辑</el-link>
                <el-link
                  style="margin-right: 11px"
                  type="text"
                  :underline="false"
                  @click.stop="delFinance(scope.row)"
                ><i class="el-icon-delete"></i>删除</el-link>
                <el-link
                  type="text"
                  :underline="false"
                  @click="showFile(scope.row)"
                ><img src="../../../assets/image/notice/query.png" alt="" style="width: 16px;height: 16px;vertical-align: -3px">查看附件</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="eachpage"
          layout="total,sizes,prev, pager, next, jumper"
          @size-change="handleSizeChange"
            :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
          :total="PageCount">
        </el-pagination>
      </div>
    </div>
    <!--      </div>-->
    <div class="voucher-upload">
      <el-dialog
        top="4vh"
        :visible.sync="dialogVisible"
        v-dialogDrag
        v-if="dialogVisible"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">上传文件</span>
        </div>
        <guide-expert :key="dialogVisible" @guide-child="guideClick"></guide-expert>
<!--        <el-form ref="addForm" label-width="130px" :model="addForm" :rules="rules">-->
<!--          <el-form-item label="文件名称:" prop="ArchivesName">-->
<!--            <el-input v-model="addForm.ArchivesName"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="发表日期:" prop="IssuingDate" >-->
<!--            <el-date-picker-->
<!--              v-model="addForm.IssuingDate"-->
<!--              type="date"-->
<!--              placeholder="选择日期"-->
<!--              format="yyyy 年 MM 月 dd 日"-->
<!--              value-format="yyyy-MM-dd">-->
<!--            </el-date-picker>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="期刊名称:" prop="JournalArticle">-->
<!--            <el-input v-model="addForm.JournalArticle"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="作者(1-N):" prop="ArchivesUser">-->
<!--            <el-input v-model="addForm.ArchivesUser"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="出版单位:" prop="Publisher">-->
<!--            <el-input v-model="addForm.Publisher"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="附件:">-->
<!--            <el-upload-->
<!--              class="upload-demo"-->
<!--              ref="newupload"-->
<!--              accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"-->
<!--              multiple-->
<!--              action="#"-->
<!--              :file-list="fileLists"-->
<!--              :auto-upload="false"-->
<!--              :on-change="handleFileChange"-->
<!--              :before-remove="handleRemove"-->
<!--            >-->
<!--              <el-button size="medium" plain icon="el-icon-top" style="color: #4d90f1;border: 1px solid #4d90f1">上传文件</el-button>-->
<!--            </el-upload>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--        <div class="btn">-->
<!--          <el-button  @click="cancel('addForm')">取消</el-button>-->
<!--          <el-button  @click="onSumbit('addForm')" :loading="loading">确定</el-button>-->
<!--        </div>-->
      </el-dialog>
    </div>
    <div class="voucher-upload">
      <el-dialog
        :visible.sync="dialogEdit"
        top="4vh"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age" v-show="editShow == '1'">编辑</span>
          <span class="title-age"  v-show="editShow == '2'">查看</span>
        </div>
        <el-form ref="addForm" label-width="130px" :model="editForm" :rules="rules">
          <el-form-item label="文件名称:" prop="ArchivesName">
            <el-input v-model="editForm.ArchivesName"></el-input>
          </el-form-item>
          <el-form-item label="发表日期:" prop="IssuingDate">
            <el-date-picker
              v-model="editForm.IssuingDate"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="期刊名称:" prop="JournalArticle">
            <el-input v-model="editForm.JournalArticle"></el-input>
          </el-form-item>
          <el-form-item label="作者(1-N):" prop="ArchivesUser">
            <el-input v-model="editForm.ArchivesUser" placeholder="如果多个作者请用英文逗号隔开"></el-input>
          </el-form-item>
          <el-form-item label="出版单位:" prop="Publisher">
            <el-input v-model="editForm.Publisher"></el-input>
          </el-form-item>
          <el-form-item label="备注:" prop="Remarks">
            <el-input type="textarea" v-model="editForm.Remarks"></el-input>
          </el-form-item>
          <el-form-item label="附件:">
            <div style="display:flex;flex-wrap: wrap;">
            <management-list :DataGuid="editForm.DataGuid" :key="editForm.Id + keyId" :Type="editShow"></management-list>
              <div v-show="editShow == '1'">
                <el-upload
                  class="upload-demo"
                  ref="newupload"
                  accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"
                  multiple
                  action="#"
                  :file-list="editFileList"
                  :auto-upload="false"
                  :on-change="eidtFileChange"
                  :before-remove="editRemove"
                >
                  <el-button size="medium" plain icon="el-icon-top" style="color: #4d90f1;border: 1px solid #4d90f1">上传文件</el-button>
                </el-upload>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="btn" v-show="editShow=='1'">
          <button  @click="dialogEdit = false">取消</button>
          <el-button  @click="submitEdit" :loading="loading" >确定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="commonVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFilesDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该选择的指南文件，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delFilesDialog = false">取消</button>
          <button @click="submitDel">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="commonVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFinanceDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该指南文件，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delFinanceDialog = false">取消</button>
          <button @click="sumDelFinance">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="dialog-file">
      <el-dialog
        top="12vh"
        :visible.sync="dialogFile"
        width="800px"
        v-dialogDrag
        v-if="dialogFile"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">附件列表</span>
        </div>
        <file-list :DataGuid="FileDataGuid"></file-list>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import FileList from "@/components/Management/FileList";
import GuideExpert from "@/components/Guide/GuideExpert"
import {ruleData} from "@/utils/validationRules";
import reseacrh from "../../../api/research.js"
import {mapState} from "vuex";
import treedata from "@/components/treedata";
import {downloadA, downloadFileId,downLoadXls} from "@/utils/downloadUrl";
import {
  addManagement, editManagement, delManagement,
  infoManagement, GetPagedManagement, TopicArchivesManagementData, exportTableManagement
} from "@/api/fileManagement.js"

export default {
  data(){
    return{
      FileDataGuid: [],
      showFileList: [], // 查看图片的列表
      dialogFile: false, // 查看附件弹框
      exportData: "ArchivesName,IssuingDate,JournalArticle,ArchivesUser,Publisher,SerialNumber,HospitalName,Remarks",
      exportDataName: "文件名称,发表日期,期刊名称,作者(1-N),出版单位,课题/子课题序号,所属单位,备注",
      keyId: "",
      rules: ruleData,
      SerialNumber: "",
      HospitalName: "",
      editShow: "",
      loading: false,
      addForm: {
        UserName: ""
      },
      radio: "1",
      DataGuidList: [], // 下载拼接的数组DataGuid
      api2: window.global_config.BASE_URL1,
      api: window.global_config.BASE_URL1 + "/TopicFiles/",
      imgList: [],
      UserName: window.sessionStorage.getItem('UserName'),
      fileLists: [],//文件列表
      DataGuid: "", // 编辑的DataGuid
      tableDataSee: [], //编辑查看到的数据列表
      editOption: [],
      delFinanceItem: {}, // 删除凭证的对象
      delFinanceDialog: false, // 删除凭证的弹框
      delFileId: null, // 删除文件的Id
      delFilesDialog: false, // 删除文件的弹框
      dialogEdit: false, // 编辑的弹框
      editForm: { // 编辑对象
      },
      Key: "",
      editFileList:[], // 上传文件列表
      dialogVisible:false, //上传文件弹框
      multipleSelection: [],//多选框数据
      tableData: [],
      imglist:[],
      TotalPages:0,
      i:0,// 项目页数
      imgLists: [], // 项目的所有图片列表
      currentPage:1,
      PageCount:0,
      eachpage:10
    }
  },
  computed: mapState(["projectId"]),
  components:{
    treedata,
    GuideExpert,
    FileList
  },
  created(){
    this.filelist()
    // this.addForm = TopicArchivesManagementData
    // this.addForm.Topicid = this.projectId
    // this.addForm.Type = 7
  },
  methods:{
    handleSizeChange(val){  //切换每页条数
      this.eachpage = val
      this.filelist()
    },
    getRowKey(row){     //回显
      return row.Id;
    },
    guideClick(data) {
      this.dialogVisible = false
      if (data) {
        this.filelist()
      }
    },
    formatter(row,column) { // 时间排序转换格式
      return row.IssuingDate.substring(0,10)
    },
    downloadimgList(src,name) { // 已知url
      downloadA(src,name)
    },
    preimgList(Url){ // 项目进来时图片列表的预览
      window.open(this.api2+Url);
    },
    projectIdClick(data) { //树型结构传递过来的课题id
      this.$store.commit('SaveId',data)
      this.currentPage = 1;
      this.eachpage = 10;
      this.PageCount = 0
      this.filelist()
    },
    // handleRemove(file, fileList) {
    //   this.fileLists = fileList;
    // },
    // handleFileChange(file, fileList) {
    //   this.fileLists = fileList;
    // },
    queryList() { // 查询
      this.filelist()
    },
    eidtFileChange(file, fileList) { // 编辑上传文件列表
      this.editFileList = fileList;
    },
    editRemove(file, fileList) { // 编辑上传取消列表
      this.editFileList = fileList;
    },
    add() { // 上传指南文件
      this.dialogVisible=true
    },
    // cancel(formName) { // 取消
    //   this.$refs[formName].resetFields();
    //   this.dialogVisible = false
    // },
    // onSumbit(formName) { //上传提交
    //   console.log(this.addForm,'值')
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       let fd = new FormData();
    //       this.fileLists.forEach(file => {
    //         fd.append('file',file.raw)
    //       })
    //       fd.append("data", JSON.stringify(this.addForm));
    //       console.log(fd,"参数")
    //       addManagement(fd).then( res=> {
    //         console.log(res)
    //         this.filelist()
    //         this.fileLists = []
    //         this.$refs[formName].resetFields();
    //         this.dialogVisible = false
    //       })
    //     } else {
    //       this.$message.error('验证失败');
    //       return false;
    //     }
    //   });
    // },
    submitEdit() { // 提交编辑
      let fd = new FormData();
      this.editFileList.forEach(file => {
        fd.append('file',file.raw)
      })
      fd.append("data", JSON.stringify(this.editForm));
      editManagement(fd).then((res) => {
        if (res.data.Status == 1) {
          console.log(res,"提交编辑")
          this.dialogEdit = false;
          this.editFileList = []
          this.filelist()
          this.TotalPages = 0
          // this.fileImgList()
          reseacrh.preFinancFile(this.editForm).then(res=>{
            if (res.data.Status==1) {
              this.tableDataSee = res.data.ExtraData
              this.keyId++ // 强制组件刷新
            }
          })
          this.$message.success(res.data.Message);
        }else{
          this.$message.error(res.data.Message)
        }
      });
    },
    seeDialog(item) { // 查看弹框
      infoManagement({Id: item.Id}).then(res => {
        if (res.data.Status == 1) {
          this.editForm = res.data.ExtraData
        }
      })
      this.DataGuid = item.DataGuid
      this.dialogEdit = true
      this.editShow = "2" // 编辑弹框
    },
    edit(item) { //编辑
      // console.log(item,'编辑')
      infoManagement({Id: item.Id}).then(res => {
        if (res.data.Status == 1) {
          this.editForm = res.data.ExtraData
        }
      })
      this.DataGuid = item.DataGuid
      this.dialogEdit = true
      this.editShow = "1" // 编辑弹框
      reseacrh.preFinancFile(item.DataGuid).then(res=>{
        if (res.data.Status==1) {
          console.log(res,'文件图片')
          this.tableDataSee = res.data.ExtraData
        }
      })
    },
    handleSelectionChange(val) { //多选
      this.multipleSelection = val;
    },
    handleCurrentChange(val){
      this.currentPage=val
      this.filelist()
    },
    showFile(item) { // 查看附件
      reseacrh.preFinancFile(item.DataGuid).then(res=>{
        if(res.data.Status==1){
          this.showFileList=res.data.ExtraData
          if (this.showFileList.length == 0) {
            this.$message.error('您还没有上传附件')
          } else if(this.showFileList.length == 1) {
            this.pre(this.showFileList[0])
          } else {
            this.FileDataGuid = res.data.ExtraData
            this.dialogFile = true
          }
        }else{
          alert(res.data.Message)
        }
      })
    },
    pre(item){
      reseacrh.preview(item.Id).then(res=>{
        console.log(res,'resresres');
        if(res.data.Status==1){
          let file = window.global_config.BASE_URL1+res.data.ExtraData.Url
          let FileName = res.data.ExtraData.FileName
          console.log();
          if(/\.(pdf|PDF|png|PNG|jpg|JPG|jpeg)$/.test(file)){
            window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
          }else{
            // window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
            this.downloadfile(item.Id)
            this.$message.warning("暂时只支持pdf文件预览")
          }
        }
      })
    },
    filelist(){
      var fileobj={
        TopicId:this.projectId,
        Key: this.Key,
        Type: [7],
        SerialNumber: this.SerialNumber,
        HospitalName: this.HospitalName,
        PageIndex:this.currentPage-1,
        PageSize:this.eachpage,
      }
      GetPagedManagement(fileobj).then(res=>{
        if(res.data.Status==1){
          console.log(res,'数据')
          this.tableData = res.data.Entity
          this.PageCount=res.data.TotalRowsCount
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    submitDel() { // 提交删除单个文件
      reseacrh.alldelet({Id:this.delFileId}).then(res=>{
        if(res.data.Status==1){
          reseacrh.preFinancFile(this.DataGuid).then(res=>{
            if (res.data.Status==1) {
              this.tableDataSee = res.data.ExtraData
              this.delFilesDialog = false
            }
          })
          this.$message.success(res.data.Message)
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    sumDelFinance() {
      delManagement({Id:this.delFinanceItem.Id}).then(res=>{
        // console.log(res)
        if(res.data.Status==1){
          this.$message.success(res.data.Message)
          this.delFinanceDialog = false
          this.filelist()
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    delFinance (item) { // 删除列表数据弹框
      this.delFinanceDialog = true
      this.delFinanceItem = item
    },
    deletefile(id){//删除文件
      this.delFileId = id
      this.delFilesDialog = true
    },
    downFiles() { // 凭证下载导出图片
      this.DataGuidList = []
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择要下载的文件')
        return  false
      }
      console.log(this.multipleSelection)
      this.multipleSelection.forEach(item => {
        this.DataGuidList.push(item.DataGuid)
      })
      reseacrh.FinancZFileZip({DataGuids: this.DataGuidList}).then(res => {
        console.log(res,'拼接图片')
        if (res.status == 200) {
          let data = res.data;
          // let filename = decodeURIComponent(res.headers.filename)
          downLoadXls(data,"指南共识.zip")
        } else {
          this.$message.error("打包文件不存在")
        }
      })
    },
    downloadfile(id){
      downloadFileId(id)
    },
    exportFiles() {
      var exportobj={
        TopicId:this.projectId,
        Key: this.Key,
        Type: [7],
        SerialNumber: this.SerialNumber,
        HospitalName: this.HospitalName,
        PageIndex:this.currentPage-1,
        PageSize:this.eachpage,
        titleName: this.exportDataName,
        titleField: this.exportData,
        ExportFileName: "指南共识"

      }
      exportTableManagement(exportobj).then(res=> {
        if (res.status == 200) {
          let data = res.data;
          let filename = decodeURIComponent(res.headers.filename)
          downLoadXls(data,filename)
        } else {
          this.$message.error("导出失败")
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../styles/file_list.scss";
</style>
