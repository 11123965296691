let ruleInput = [{ required: true, message: '输入框不能为空', trigger: 'blur' }]
let ruleTime = [{ required: true, message: '请选择日期', trigger: 'change' }]
export const ruleData = {
    ArchivesCode: ruleInput, //证书号,检索号
    ArchivesName: ruleInput, //档案名称，论文名称，软件名称，文件名称
    IssuingDate: ruleTime, //发表日期，申请日期
    JournalArticle: ruleInput, // 期刊名称
    ArchivesUser: ruleInput,
    Category: ruleInput,
    Publisher: ruleInput,
    // Source: ruleInput,
    Scope: ruleInput,
    ArchivesNumber: ruleInput,
    AuthorizationDate: ruleTime,
    AuthorizationNumber: ruleInput,
    PatentUser: ruleInput,
    Address: ruleInput,
    DevelopmentDate: ruleTime,
    FirstDate: ruleTime,
    GetDate: ruleTime,
    Method: ruleInput,
    CreateDate: ruleTime,
    Searching: ruleInput,
    // Factor: ruleInput
}
export const ruleData1 = {
    AmountReceived:ruleInput,
    ArrivalDate:ruleInput
}
export const ruleData2 = {
    Event:ruleInput,
    FinishDate:ruleInput,
    Achievement:ruleInput,
    Standard:ruleInput,
}
