<template>
  <div>
    <div class="dialog-file">
        <el-table
          :data="DataGuid"
          max-height="610"
          :header-cell-style="{
                color: '#333333',
                backgroundColor: '#FBFBFD',
              }"
          row-key="id"
          border
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="80">
          </el-table-column>
          <el-table-column
            prop="FileName"
            label="文件名称"
            align="center"
            min-width="200">
            <template slot-scope="scope">
              <span>{{scope.row.FileName}}</span>
              <span>.</span>
              <span>{{scope.row.Suffix}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="180">
            <template slot-scope="scope">
              <el-link
                style="margin-right: 11px"
                type="text"
                :underline="false"
                @click="pre(scope.row)"
              ><i class="el-icon-edit-outline"></i>查看</el-link>
              <el-link
                style="margin-right: 11px"
                type="text"
                :underline="false"
                @click="downloadfile(scope.row.Id)"
              ><i class="el-icon-bottom"></i>下载</el-link>
            </template>
          </el-table-column>
        </el-table>
    </div>
  </div>
</template>

<script>
import {downloadFileId} from "@/utils/downloadUrl";
import reseacrh from "@/api/research";

export default {
name: "FileList",
  data() {
    return {
    }
  },
  props: ["DataGuid"],
  created() {
  },
  methods: {
    downloadfile(id){
      downloadFileId(id)
    },
    pre(item){ // 点开查看文件
      reseacrh.preview(item.Id).then(res=>{
        console.log(res);
        if(res.data.Status==1){
          let file = window.global_config.BASE_URL1+res.data.ExtraData.Url
          if(/\.(pdf|PDF|png|PNG|jpg|JPG|jpeg)$/.test(file)){
            window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
          }else{
            this.$message.warning("暂时只支持pdf文件预览,请下载查看")
          }
        }
      })
    },
  }
}
</script>

